import React, { useContext } from 'react';
import { arrayOf, func, string } from 'prop-types';
import {
  useDataModel,
  extend,
  arrayOf as arrayOfType,
  customType,
  params,
  string as stringType,
  QueryProvider,
  QueryContext,
} from '@thd-nucleus/data-sources';
import { CustomProductPod } from '../components/compare-pods/CustomProductPod';

const ProductModel = extend({}, CustomProductPod.dataModel.product);

const CompareSearchNavLoader = ({ itemIds, storeId, children, deliveryZip }) => {

  const { defaultVariables, isClientResolved } = useContext(QueryContext);
  const skipFn = ({ queryName, attributes, skip, response }) => {
    if (queryName !== 'product') {
      return skip;
    }
    const isResolved = isClientResolved({ queryName: 'searchModel' });
    if (attributes.product.fulfillment) {
      if (!isResolved) {
        return true;
      }
    }
    return skip;
  };

  const newDefaultVariables = {
    storeId,
    skipInstallServices: false,
    skipSubscribeAndSave: true,
    ...(typeof defaultVariables?.current?.isBrandPricingPolicyCompliant === 'boolean' && {
      isBrandPricingPolicyCompliant: defaultVariables.current.isBrandPricingPolicyCompliant
    })
  };

  const opts = {
    ssr: false,
    skip: storeId === '8119',
    variables: {
      itemIds,
      storeId,
      additionalSearchParams: {
        deliveryZip // || '30339' // for dev use '97527' in order to see images
      },
    }
  };
  const response = useDataModel('searchModel', opts);
  const { data } = response;

  if (!data) return null;

  return (
    <QueryProvider
      dataSource="searchNav"
      cacheKey="compare-searchNav-loader"
      defaultVariables={newDefaultVariables}
      skip={skipFn}
    >
      {children(data)}
    </QueryProvider>
  );
};

CompareSearchNavLoader.propTypes = {
  itemIds: arrayOf(string),
  storeId: string,
  children: func.isRequired,
  deliveryZip: string

};

CompareSearchNavLoader.defaultProps = {
  itemIds: [],
  storeId: '',
  deliveryZip: '30339'
};

CompareSearchNavLoader.dataModel = {
  searchModel: params({
    itemIds: arrayOfType(stringType()),
    storeId: stringType(),
    channel: customType('Channel').enum(['DESKTOP', 'MOBILE', 'TABLET'], 'DESKTOP'),
    additionalSearchParams: customType('AdditionalParams').shape({
      deliveryZip: stringType()
    })
  }).shape({
    id: stringType(),
    products: params()
      .arrayOf(ProductModel)
  })
};

export { CompareSearchNavLoader };
