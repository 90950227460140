import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@thd-olt-component-react/core-ui';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import {
  params, string, shape, bool, client, arrayOf, number, useDataModel
} from '@thd-nucleus/data-sources';
import classNames from 'classnames';
import { podFulFillmentUtils } from '@thd-olt-component-react/fulfillment';
import { useImpression } from '@thd-olt-component-react/impression';
import { ProductPodGroupContext } from '../ProductPodGroupContext';
import './product-see-similar-items.scss';

const isSimilarItemsEligible = () => {
  // @TODO - this needs to be data driven
  let searchCookie = '';
  if (typeof window !== 'undefined') {
    searchCookie = window.cookieUtils?.readBrowserCookie('x-ttsearch') || '';
  }
  const searchOptions = searchCookie.split(',');
  const isEligible = searchOptions.filter((opt) => opt === 'INSTOCK_link').length === 1;
  return isEligible;
};

const Elements = typeof Element === 'undefined' ? function () {} : Element;

export const ProductSeeSimilarItems = ({
  itemId,
  showSimilarInStock,
  productPodRef,
  position,
  storeId,
  showSpacer,
  checkInStockLink
}) => {
  const {
    handleProductPodExpansion
  } = useContext(ProductPodGroupContext);

  const { channel } = useContext(ExperienceContext);
  const { data, loading } = useDataModel('product', {
    variables: {
      itemId,
      storeId
    },
  });

  const similarButtonEnabled = !showSimilarInStock;
  const seeSimilarText = showSimilarInStock ? 'View' : 'See';

  const isMobile = channel === 'mobile';
  const SimilarInStockButton = classNames({
    'see-similar-items__customs--margin': similarButtonEnabled && isMobile,
    'similar-items-link': !similarButtonEnabled,
    'similar-items-link__caret': showSimilarInStock
  });

  const { additionalData = {} } = useImpression({
    data: {
      id: itemId,
      component: 'ProductSeeSimilarItems',
      name: 'ProductSeeSimilarItems',
      position: 0
    }
  });

  const { parent } = additionalData;

  const product = data?.product;

  if (!loading && !product?.fulfillment) return null;

  const { isFulfillable } = podFulFillmentUtils;
  const { info } = product || {};
  const { isBuryProduct = false } = info || {};

  const fulfillable = isFulfillable(product) || !isBuryProduct;

  const onSimilarItemsClick = (enabled) => {

    window.LIFE_CYCLE_EVENT_BUS.trigger('product-see-similar-items.click', { parent, product });
    handleProductPodExpansion(itemId, position, productPodRef, enabled);
  };

  if ((checkInStockLink && !isSimilarItemsEligible()) || !handleProductPodExpansion) {
    return null;
  }

  if (product?.info?.productSubType?.name === 'HDQC') {
    return null;
  }
  const outOfStockAndNoShowSimilarInStock = !showSimilarInStock && fulfillable;
  const notOutOfStockAndShowSimilarInStock = showSimilarInStock && !fulfillable;
  if (outOfStockAndNoShowSimilarInStock || notOutOfStockAndShowSimilarInStock) {
    if (showSpacer) {
      return (<div className="similar-items-link-spacer" />);
    }
    return null;
  }

  const similarItemBtnClick = () => onSimilarItemsClick(similarButtonEnabled || showSimilarInStock);

  return (
    <Button
      tag="a"
      className={SimilarInStockButton}
      link={!similarButtonEnabled}
      outline={similarButtonEnabled}
      small={similarButtonEnabled && isMobile}
      onClick={similarItemBtnClick}
      data-testid="similar-items-button"
      data-component="ProductSeeSimilarItems"
    >
      {similarButtonEnabled ? 'View Similar in Stock' : `${seeSimilarText} similar items`}
    </Button>
  );
};

ProductSeeSimilarItems.dataModel = {
  product: params({ itemId: string().isRequired() }).shape({
    itemId: string(),
    dataSources: string(),
    info: shape({
      isBuryProduct: bool(),
      productSubType: shape({
        name: string()
      })
    }),
    availabilityType: shape({
      discontinued: bool()
    }),
    fulfillment: client(params({ storeId: string(), zipCode: string() }).shape({
      fulfillmentOptions: arrayOf(shape({
        type: string(),
        fulfillable: bool(),
        services: arrayOf(shape({
          type: string(),
          locations: arrayOf(shape({
            inventory: shape({
              quantity: number()
            }),
            type: string()
          }))
        }))
      }))
    }))
  })
};

ProductSeeSimilarItems.propTypes = {
  showSimilarInStock: PropTypes.bool,
  position: PropTypes.number.isRequired,
  storeId: PropTypes.string.isRequired,
  itemId: PropTypes.string.isRequired,
  productPodRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Elements) })
  ]).isRequired,
  showSpacer: PropTypes.bool,
  checkInStockLink: PropTypes.bool
};

ProductSeeSimilarItems.defaultProps = {
  showSimilarInStock: false,
  showSpacer: false,
  checkInStockLink: false
};

ProductSeeSimilarItems.displayName = 'ProductSeeSimilarItems';
