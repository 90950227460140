import { useState, useEffect } from 'react';

const isBrowser = typeof window !== 'undefined';

const getScrollPosition = () => {
  return isBrowser ? { xpos: window.pageXOffset, ypos: window.pageYOffset } : { xpos: 0, ypos: 0 };
};

export const useScrollPosition = () => {
  const [position, setScrollPosition] = useState(getScrollPosition());

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    let requestRunning = null;
    function handleScroll() {
      if (isBrowser && requestRunning === null) {
        requestRunning = window.requestAnimationFrame(() => {
          setScrollPosition(getScrollPosition());
          requestRunning = null;
        });
      }
    }

    if (isBrowser) {
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }
  }, []);

  return position;
};

export const useScrollXPosition = () => {
  const { xpos } = useScrollPosition();
  return xpos;
};

export const useScrollYPosition = () => {
  const { ypos } = useScrollPosition();
  return ypos;
};