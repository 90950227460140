import React, { useState, useContext, useEffect, useRef } from 'react';
import { Col, Row } from '@thd-olt-component-react/core-ui';
import { useNavigate, useLocation } from '@thd-olt-component-react/router';
import {
  ComparePageHeader,
  ComparePods,
  CompareSpecifications,
  CompareCatalogLoader,
  CompareSearchNavLoader
} from '@thd-olt-component-react/compare';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { QueryProvider, extend, QueryContext } from '@thd-nucleus/data-sources';
import { useSearchDeprecated } from '@thd-nucleus/data-sources/react/dataModel/migration';
import '../../styles/compare-page.scss';

// For IE - URLSearchParams is not supported in IE.
const getUrlSearchParams = (queryString) => {
  return {
    get: (param) => {
      const searchParam = new RegExp('[?&]' + param + '=([^&#]*)').exec(queryString);
      return searchParam && decodeURI(searchParam[1]);
    }
  };
};

export const Compare = () => {
  const isBrowser = typeof window !== 'undefined';
  const { defaultVariables } = useContext(QueryContext);
  const { channel, store: { storeId } } = useContext(ExperienceContext);
  const [hideSimilar, setHideSimilar] = useState(false);
  const [scrollPos, setScrollPos] = useState({ xpos: 0, ypos: 0 });

  const navigate = useNavigate();
  const { search: querystring } = useLocation();

  const urlParams = isBrowser && window.URLSearchParams
    ? new window.URLSearchParams(querystring) : getUrlSearchParams(querystring);

  const items = [
    urlParams.get('item1'),
    urlParams.get('item2'),
    urlParams.get('item3'),
    urlParams.get('item4')
  ].filter((itemId) => itemId);

  const [itemIds, setItemIds] = useState(items);
  const ref = useRef();

  if (isBrowser) window.sessionStorage.setItem('compare', JSON.stringify(itemIds));

  const scrolled = () => {
    setScrollPos({
      xpos: ref.current.scrollLeft,
      ypos: ref.current.scrollTop
    });
  };

  const getInStoreFilter = () => {
    const isInStoreFilter = [
      urlParams.get('inStoreFilter')
    ].filter((inStoreFilter) => inStoreFilter);
    return isInStoreFilter[0] === 'true';
  };

  const updateQueryParams = (compareItems) => {
    let queryparams = compareItems
      .map((item, idx) => `item${idx + 1}=${item}`)
      .join('&');

    queryparams += getInStoreFilter() ? '&inStoreFilter=true' : '';

    navigate(`/compare?${queryparams}`, { replace: true });

  };

  const onRemove = (id) => {
    const updatedItems = itemIds.filter((item) => item !== id);
    setItemIds(updatedItems);
    updateQueryParams(updatedItems);
  };

  const onBack = () => {
    navigate(-1);
  };

  const stringifyItemIds = (hookData) => {
    return hookData?.searchModel?.products?.map(({ itemId: sku }) => sku)
      .join('-');
  };

  const { error, loading, data } = useSearchDeprecated({
    itemIds,
    storeId,
    domains: ['products'],
    productDomains: ['$ProductPod', '$ProductPodInstallation'],
    ssr: false
  });

  useEffect(() => {
    if (data && !loading) {
      LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('compare.new-products', data?.searchModel);
    }
  }, [stringifyItemIds(data)]);

  if (!data || loading) return null;

  return (

    <Row className="isBound compare">
      <Col nopadding={channel !== 'desktop'} fallback={12}>
        <Row className="no-auto">
          <Col nopadding fallback={12}>
            <ComparePageHeader onBack={onBack} />
          </Col>
        </Row>
        <div className="compare__wrapper" ref={ref} onScroll={scrolled}>
          <Col nopadding className={`compare__content ${'compare__content--' + itemIds.length}`} fallback={12}>
            <QueryProvider cacheKey="compare-pods" dataSource="searchNav" defaultVariables={defaultVariables.current}>
              <CompareSearchNavLoader itemIds={itemIds} storeId={storeId}>
                {() => (
                  <ComparePods
                    itemIds={itemIds}
                    onRemove={onRemove}
                    onHideSimilar={() => { setHideSimilar(!hideSimilar); }}
                    hasInStoreFilter={getInStoreFilter()}
                    hideSimilar={hideSimilar}
                  />
                )}
              </CompareSearchNavLoader>
            </QueryProvider>
            <QueryProvider cacheKey="compare-specs" defaultVariables={defaultVariables.current}>
              <CompareCatalogLoader itemIds={itemIds} storeId={storeId}>
                {() => (
                  <CompareSpecifications itemIds={itemIds} hideSimilar={hideSimilar} />
                )}
              </CompareCatalogLoader>
            </QueryProvider>
          </Col>
        </div>
      </Col>
    </Row>
  );
};

Compare.dataModel = extend({}, CompareSearchNavLoader, CompareSpecifications);
Compare.displayName = 'Compare';