import React from 'react';
import PropTypes from 'prop-types';
import { extend } from '@thd-nucleus/data-sources';
import { Close } from '@one-thd/sui-icons';
import { Button, Typography } from '@one-thd/sui-atomic-components';
import {
  ProductPod,
  PodSticker,
  ProductATC,
  ProductRating,
  ProductSample,
  ProductServiceAddOns,
  ProductIdentifier,
  ProductBadge,
  ProductSponsored,
  ProductImage,
  PodFooter,
  PodSection,
  ProductCustomOptions,
  PodSpacer
} from '@thd-olt-component-react/product-pod';
import { ProductSeeSimilarItems } from '@thd-olt-component-react/product-pod-group';
import { AddToList } from '@thd-olt-component-react/add-to-list';
import { KeyProductFeatures } from '@thd-olt-component-react/key-product-features';
import { FulfillmentPodShipping, FulfillmentPodStore } from '@thd-olt-component-react/fulfillment';
import { SuperSkuMiniSwatches } from '@thd-olt-component-react/super-sku';
import { Price, PriceClearanceDataModel } from '@thd-olt-component-react/price';
import { ProductHeader } from '@thd-olt-component-react/product-details';
import './compare-pods.scss';

export const CustomProductPod = ({
  hasInStoreFilter,
  hideFavorites,
  isB2BCustomer,
  itemId,
  onRemove,
  position,
  removable,
  storeId
}) => {
  return (
    <ProductPod
      itemId={itemId}
      storeId={storeId}
      analyticsData={{ parent: 'plp', position }}
      key={position}
      render={(pod) => (
        <>
          <PodSticker position="top-left">
            <ProductBadge itemId={pod.itemId} storeId={storeId} />
            <ProductSponsored itemId={pod.itemId} position={position} />
          </PodSticker>
          {!isB2BCustomer && !hideFavorites && (
            <PodSticker position="top-right">
              <PodSpacer padding={['TOP', 'RIGHT']}>
                <AddToList itemId={pod.itemId} showCount showIconButton />
              </PodSpacer>
            </PodSticker>
          )}
          <ProductImage
            itemId={pod.itemId}
            showSecondaryImage={pod.showSecondaryImage}
          />
          <PodSection columnAlign alignTop>
            {!isB2BCustomer && <ProductCustomOptions itemId={pod.itemId} />}
            <ProductSample itemId={pod.itemId} />
            <PodSpacer minHeight="75px">
              <SuperSkuMiniSwatches
                itemId={pod.itemId}
                onChange={pod.onChange}
                onHover={pod.onSSKUHover}
              />
            </PodSpacer>
            {!isB2BCustomer && <ProductServiceAddOns itemId={pod.itemId} />}
            <PodSpacer minHeight="60px" noPadding>
              <Price
                itemId={pod.itemId}
                large={false}
                storeId={storeId}
                displayEachUom={false}
                onSavingsCenterToggle={pod.onSavingsCenterToggle}
                showPreferredPricingBadge={isB2BCustomer}
              />
            </PodSpacer>
            <ProductRating itemId={pod.itemId} />
            <PodSpacer padding={['BOTTOM']}>
              <ProductIdentifier itemId={pod.itemId} type="model" />
              {isB2BCustomer && (
                <>
                  <ProductIdentifier itemId={pod.itemId} type="itemId" />
                  <ProductIdentifier itemId={pod.itemId} type="storeSku" />
                </>
              )}
              <PodSpacer padding={['TOP', 'BOTTOM']} />
            </PodSpacer>
            <PodSpacer padding={['TOP', 'BOTTOM']}>
              <ProductHeader
                brand="above"
                itemId={pod.itemId}
                brandTitleMaxLine={4}
              />
              <PodSpacer padding={['TOP', 'BOTTOM']} />
            </PodSpacer>
            <PodSpacer padding={['TOP']}>
              <KeyProductFeatures
                hideImage
                storeId={storeId}
                itemId={pod.itemId}
                maxfeatures={5}
                simple
                oneColumnGrid
              />
            </PodSpacer>
          </PodSection>
          <PodFooter>
            <PodSpacer minHeight="107px" padding={['BOTTOM', 'LEFT', 'RIGHT']}>
              <FulfillmentPodStore itemId={pod.itemId} storeId={storeId} hideForChooseYourOptions={!isB2BCustomer} />
              <FulfillmentPodShipping itemId={pod.itemId} storeId={storeId} />
            </PodSpacer>
            <PodSpacer padding={['TOP', 'LEFT', 'RIGHT']}>
              <ProductATC
                itemId={pod.itemId}
                storeId={storeId}
                checkGeneric
                variant={isB2BCustomer ? 'primary' : 'secondary'}
                hasInStoreFilter={hasInStoreFilter}
              />
            </PodSpacer>
            {isB2BCustomer && (
              <PodSpacer padding={['TOP', 'LEFT', 'RIGHT']}>
                <AddToList
                  itemId={pod.itemId}
                  storeId={storeId}
                />
              </PodSpacer>
            )}
            {!isB2BCustomer && (
              <ProductSeeSimilarItems
                itemId={pod.itemId}
                position={position}
                productPodRef={pod.ref}
                storeId={storeId}
              />
            )}
            {removable && (
              <PodSpacer padding={['TOP', 'LEFT', 'RIGHT']}>
                <div className="compare-pods__remove-wrapper">
                  <Button variant="ghost" startIcon={Close} onClick={() => onRemove(pod.itemId)}>
                    Remove
                  </Button>
                </div>
              </PodSpacer>
            )}
          </PodFooter>
        </>
      )}
    />
  );
};

CustomProductPod.propTypes = {
  hasInStoreFilter: PropTypes.bool.isRequired,
  hideFavorites: PropTypes.bool.isRequired,
  isB2BCustomer: PropTypes.bool.isRequired,
  itemId: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  position: PropTypes.number.isRequired,
  removable: PropTypes.bool.isRequired,
  storeId: PropTypes.string.isRequired
};

CustomProductPod.dataModel = extend(
  ProductPod,
  ProductImage,
  ProductServiceAddOns,
  AddToList,
  Price,
  PriceClearanceDataModel,
  SuperSkuMiniSwatches,
  ProductATC,
  ProductRating,
  ProductSample,
  ProductHeader,
  ProductIdentifier,
  ProductBadge,
  ProductSponsored,
  FulfillmentPodStore,
  FulfillmentPodShipping,
  ProductCustomOptions,
  KeyProductFeatures,
  ProductSeeSimilarItems
);
