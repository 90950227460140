/* eslint-disable max-len */
import React, { useContext, Fragment } from 'react';
import { arrayOf, string, bool } from 'prop-types';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { useProductDeprecated } from '@thd-nucleus/data-sources/react/dataModel/migration';
import { dataModel } from './dataModel';
import './compare-specifications.scss';

const CompareSpecifications = ({ itemIds, hideSimilar }) => {

  const GROUP_TYPES = {
    DIMENSIONS: 'Dimensions',
    DETAILS: 'Details',
    WARRANTY: 'Warranty / Certifications'
  };
  const { channel } = useContext(ExperienceContext);

  const equal = (array) => array.every((value) => value === array[0]);

  const getSpecNames = (data, title) => data.map((product) => (product?.specificationGroup || [])
    ?.filter((group) => group?.specTitle === title)[0]
    ?.specifications
    ?.map((specification) => specification?.specName)
  );

  const getSpecValues = (data, title, name) => data.map((product) => (product?.specificationGroup || [])
    ?.filter((group) => group?.specTitle === title)[0]
    ?.specifications
    ?.filter((specification) => specification?.specName === name)[0]?.specValue || '-'
  );

  const getSpecData = (data, title, name) => {
    const values = getSpecValues(data, title, name);
    const isEqual = equal(values);
    return {
      name,
      isEqual,
      values,
      columns: values.length
    };
  };

  const getSpecificationData = (products) => {
    const specProducts = itemIds.map((itemId) => {
      let product = {};
      const temp = products.filter((prod) => prod.itemId === itemId);
      if (temp && temp.length) product = temp[0];
      return product;
    });
    const detailsNames = [...new Set(getSpecNames(specProducts, GROUP_TYPES.DETAILS).join(',').split(','))].sort();
    const warrantyNames = [...new Set(getSpecNames(specProducts, GROUP_TYPES.WARRANTY).join(',').split(','))].sort();
    const dimensionNames = [...new Set(getSpecNames(specProducts, GROUP_TYPES.DIMENSIONS).join(',').split(','))].sort();

    const details = detailsNames.map((name) => getSpecData(specProducts, GROUP_TYPES.DETAILS, name));
    const warranty = warrantyNames.map((name) => getSpecData(specProducts, GROUP_TYPES.WARRANTY, name));
    const dimensions = dimensionNames.map((name) => getSpecData(specProducts, GROUP_TYPES.DIMENSIONS, name));

    return {
      DETAILS: details,
      WARRANTY: warranty,
      DIMENSIONS: dimensions
    };
  };

  const domains = ['specificationGroup'];
  const { error, loading, data } = useProductDeprecated({
    domains,
    itemIds
  }, 'products');

  if (loading || (error && !data) || !data?.products) return null;

  const specificationData = getSpecificationData(data.products);

  const renderForDesktop = () => (
    <div className="compare-specifications" data-component="CompareSpecificationsDesktop">
      <h1 className="compare-specifications__title">Specifications</h1>
      <div className="compare-specifications__card">
        <h2 className="compare-specifications__card-title">{GROUP_TYPES.DIMENSIONS}</h2>
        <div className="compare-specifications__table">
          {specificationData.DIMENSIONS.map((dimension, key) => {
            if (hideSimilar && dimension.isEqual) return null;
            return (
              <div className="compare-specifications__table-row" key={key}>
                <div className="compare-specifications__table-cell">{dimension.name}</div>
                {dimension.values.map((value, idx) => {
                  const dimensionKey = `value-${idx}`;
                  return <div className="compare-specifications__table-cell" key={dimensionKey}>{value}</div>;
                })}
              </div>
            );
          })}
        </div>
      </div>
      <div className="compare-specifications__card">
        <h2 className="compare-specifications__card-title">{GROUP_TYPES.DETAILS}</h2>
        <div className="compare-specifications__table">
          {specificationData.DETAILS.map((details, key) => {
            if (hideSimilar && details.isEqual) return null;
            return (
              <div className="compare-specifications__table-row" key={key}>
                <div className="compare-specifications__table-cell">{details.name}</div>
                {details.values.map((value, idx) => {
                  const detailsKey = `value-${idx}`;
                  return <div className="compare-specifications__table-cell" key={detailsKey}>{value}</div>;
                })}
              </div>
            );
          })}
        </div>
      </div>
      <div className="compare-specifications__card">
        <h2 className="compare-specifications__card-title">{GROUP_TYPES.WARRANTY}</h2>
        <div className="compare-specifications__table">
          {specificationData.WARRANTY.map((warranty, key) => {
            if (hideSimilar && warranty.isEqual) return null;
            return (
              <div className="compare-specifications__table-row" key={key}>
                <div className="compare-specifications__table-cell">{warranty.name}</div>
                {warranty.values.map((value, idx) => {
                  const warrantyKey = `value-${idx}`;
                  return <div className="compare-specifications__table-cell" key={warrantyKey}>{value}</div>;
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );

  const renderForMobile = () => (
    <div className="compare-specifications" data-component="CompareSpecificationsMobile">
      <h1 className="compare-specifications__title"><span>Specifications</span></h1>
      <div className="compare-specifications__card">
        <h2 className="compare-specifications__card-title"><span>{GROUP_TYPES.DIMENSIONS}</span></h2>
        <div className="compare-specifications__table">
          {specificationData.DIMENSIONS.map((dimension, key) => {
            if (hideSimilar && dimension.isEqual) return null;
            return (
              <Fragment key={key}>
                <div className="compare-specifications__table-row">
                  <div className="compare-specifications__table-title">
                    <span>{dimension.name}</span>
                  </div>
                </div>
                <div className="compare-specifications__table-row">
                  {dimension.values.map((value, idx) => {
                    const dimensionKey = `dimension-${idx}`;
                    return <div className="compare-specifications__table-cell" key={dimensionKey}>{value}</div>;
                  })}
                </div>
              </Fragment>
            );
          })}
        </div>
      </div>
      <div className="compare-specifications__card">
        <h2 className="compare-specifications__card-title"><span>{GROUP_TYPES.DETAILS}</span></h2>
        <div className="compare-specification__table">
          {specificationData.DETAILS.map((detail, key) => {
            if (hideSimilar && detail.isEqual) return null;
            return (
              <Fragment key={key}>
                <div className="compare-specifications__table-row">
                  <div className="compare-specifications__table-title">
                    <span>{detail.name}</span>
                  </div>
                </div>
                <div className="compare-specifications__table-row">
                  {detail.values.map((value, idx) => {
                    const detailKey = `detail-${idx}`;
                    return <div className="compare-specifications__table-cell" key={detailKey}>{value}</div>;
                  })}
                </div>
              </Fragment>
            );
          })}
        </div>
      </div>
      <div className="compare-specifications__card">
        <h2 className="compare-specifications__card-title"><span>{GROUP_TYPES.WARRANTY}</span></h2>
        <div className="compare-specification__table">
          {specificationData.WARRANTY.map((warranty, key) => {
            if (hideSimilar && warranty.isEqual) return null;
            return (
              <Fragment key={key}>
                <div className="compare-specifications__table-row">
                  <div className="compare-specifications__table-title">
                    <span>{warranty.name}</span>
                  </div>
                </div>
                <div className="compare-specifications__table-row">
                  {warranty.values.map((value, idx) => {
                    const warrantyKey = `warranty-${idx}`;
                    return <div className="compare-specifications__table-cell" key={warrantyKey}>{value}</div>;
                  })}
                </div>
              </Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );

  if (channel === 'desktop') return renderForDesktop();

  return renderForMobile();
};

CompareSpecifications.displayName = 'CompareSpecifications';

CompareSpecifications.propTypes = {
  itemIds: arrayOf(string).isRequired,
  hideSimilar: bool
};

CompareSpecifications.defaultProps = {
  hideSimilar: false
};

CompareSpecifications.dataModel = dataModel;

export { CompareSpecifications };
