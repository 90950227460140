const getCustomBlindsUrl = ({
  canonicalUrl,
  isBuildAndBuyProduct,
  blindsHost
}) => {
  if (!isBuildAndBuyProduct) {
    return canonicalUrl;
  }
  const regex = /\/p\//gi;
  const customBlindsPath = '/p/custom-blinds/';
  const customProductUrl = canonicalUrl && canonicalUrl.replace(regex, customBlindsPath);
  return `${blindsHost}${customProductUrl}`;
};

export const getCustomUrlWithAnalytics = ({
  canonicalUrl,
  isBuildAndBuyProduct,
  blindsHost,
}) => {
  let productURL = getCustomBlindsUrl({ canonicalUrl,
    isBuildAndBuyProduct,
    blindsHost });
  return productURL;
};

export const smoothScroll = (event, target, offsetselector) => {
  const getElementHeight = (element) => {
    const clone = element.cloneNode(true);

    clone.style.position = 'absolute';
    clone.style.visibility = 'hidden';
    clone.style.display = 'block';
    document.body.append(clone);

    const elementHeight = clone.getBoundingClientRect().height;

    clone.remove();
    return elementHeight;
  };

  const getOffset = (offsetSelector) => {
    return offsetselector && document.querySelector(`${offsetSelector}`)
      ? getElementHeight(document.querySelector(`${offsetSelector}`))
      : null;
  };

  const currentPosition = () => {
    return window.scrollY || window.pageYOffset;
  };

  const targetPosition = (element) => {
    const start = currentPosition();
    return element.getBoundingClientRect().top + start;
  };

  event.preventDefault();

  if (target && offsetselector) {
    const targetElement = target
      ? document.querySelector(`${target}`)
      : null;
    if (typeof window !== 'undefined') {
      const targetLocation = targetPosition(targetElement) - getOffset(offsetselector);

      window.scroll({
        top: targetLocation,
        behavior: 'smooth'
      });
    }
  }
};

export const getProductObject = (reviewStats, totalResults) => {
  const { store } = reviewStats?.Includes?.Products || {};
  const reviewCount = store?.FilteredReviewStatistics?.TotalReviewCount;
  if (reviewCount === totalResults) {
    return store || null;
  }
  const itemObject = reviewStats?.Includes?.Products?.items
    ?.find((el) => el?.FilteredReviewStatistics?.TotalReviewCount === totalResults);
  return itemObject || store;
};

export const getRatingDistribution = (totalReviewCount, distribution) => {
  let stats = [{
    ratingValue: 5,
    count: 0,
    ratingPercentage: '0',
  },
  {
    ratingValue: 4,
    count: 0,
    ratingPercentage: '0',
  },
  {
    ratingValue: 3,
    count: 0,
    ratingPercentage: '0',
  },
  {
    ratingValue: 2,
    count: 0,
    ratingPercentage: '0',
  },
  {
    ratingValue: 1,
    count: 0,
    ratingPercentage: '0',
  },
  ];
  return stats.map((element) => {
    const starDistribution = distribution?.filter((el) => el.RatingValue === element.ratingValue)[0];
    if (starDistribution) {
      return {
        ratingValue: starDistribution.RatingValue,
        count: starDistribution.Count,
        ratingPercentage: Math.round((starDistribution.Count / totalReviewCount) * 100).toString(),
      };
    } return element;
  });
};

export const getRecommendationsPercentage = (filteredReviewStatistics) => {
  const recommendedCount = filteredReviewStatistics?.RecommendedCount || 0;
  const notRecommendedCount = filteredReviewStatistics?.NotRecommendedCount || 0;
  const sumRecommended = recommendedCount + notRecommendedCount;
  const recommendationPercentage = sumRecommended ? Math.round((recommendedCount / sumRecommended) * 100) : 0;

  return recommendationPercentage;
};

export const getReviewInfo = (itemObj, product, totalResults) => {
  // const TotalResults = reviewStats?.TotalResults;
  const totalReviews = totalResults || product?.reviews?.ratingsReviews?.totalReviews || 0;
  const reviewCount = typeof TotalResults === 'number' ? totalResults : totalReviews;
  const averageRating = reviewCount === 0 ? 0
    : itemObj?.FilteredReviewStatistics?.AverageOverallRating
      || product?.reviews?.ratingsReviews?.averageRating
      || 0;
  const averageRatingNumber = Number(averageRating);

  let ratingsTitle;
  if (averageRatingNumber === 0) {
    ratingsTitle = 'Write the first Review';
  } else {
    ratingsTitle = `${averageRatingNumber.toFixed(1)} out of 5`;
  }

  return { averageRating: averageRatingNumber, ratingsTitle };

};